import React from 'react';
import { connect } from 'react-redux';

import service from '../api/service';
import history from '../history';
import { fetchCustomer, fetchDrivers } from '../actions';
import gps from '../api/gps';
import tvs from '../api/tvs';
import Detail from '../components/Detail';

class OrderDetail extends React.Component {
    state = {
        time: 0,
        fromLat: null,
        fromLng: null,
        toLat: null,
        toLng: null,
        showDirection: false,
        isLoading: true,
        distance: '',
        duration: '',
        showEta: false,
        grade: []
    }

    async componentDidMount() {
        if (!this.props.authen.isLogin) {
            history.push('/Orders');
        } else {
            const response = await gps.get(`/get_devices?lang=en&user_api_hash=$2y$10$M7lEukYG.deGQH40WeS2/.pc8Sp9yCvinJX8l6trXzUNUVERG8Isq`);
            if (response.status === 200) {
                this.props.fetchDrivers(response.data)
            }
            const customer = await tvs.post('/TVS/GetCustomer', { CustomerCode: this.props.order.shipToCode }, {
                headers: { Authorization: `Bearer ${this.props.authen.token}` }
            });
            const { ResponseCode, ResponseData } = customer.data;
            if (customer.status === 200 && ResponseCode === '00' && ResponseData.ResCustomerInfo.length > 0) {

                this.props.fetchCustomer(ResponseData.ResCustomerInfo[0]);
                const { driver, customer, order } = this.props;
                const driverUsers = driver.find(user => user.items.find(item => item.name === order.vehicleLicenseHead));

                const grade = await tvs.post('/TVS/GetGradeList', { hashValue: this.props.order.hashValueDock }, {
                    headers: { Authorization: `Bearer ${this.props.authen.token}` }
                })
                if (grade.status === 200 && grade.data.ResponseCode === '00') {
                    this.setState({ grade: grade.data.ResponseData.gradeList });
                }
                if (driverUsers === undefined) {
                    this.setState({ toLat: customer.Latitude, toLng: customer.Longitude, isLoading: false });
                } else {
                    const driverUser = driverUsers.items.find(driver => driver.name === order.vehicleLicenseHead);
                    if (Number(driverUser.lat) === 0 || Number(driverUser.lng) === 0 || Number(customer.Latitude) === 0 || Number(customer.Longitude) === 0) {
                        if (driverUser.lat !== undefined && driverUser.lat !== null && Number(driverUser.lat) !== 0) {
                            this.setState({ fromLat: driverUser.lat, fromLng: driverUser.lng });
                        } else if (customer.Latitude !== undefined && customer.Latitude !== null && Number(customer.Latitude) !== 0) {
                            this.setState({ toLat: customer.Latitude, toLng: customer.Longitude });
                        }
                        this.setState({ isLoading: false, showDirection: true, showEta: true })
                    } else {
                        var matrix = await service.post('/distance', {
                            fromLat: driverUser.lat,
                            fromLng: driverUser.lng,
                            toLat: customer.Latitude,
                            toLng: customer.Longitude
                        });
                        if (matrix.status === 200) {
                            const { duration, distance } = matrix.data.rows[0].elements[0];
                            this.setState({ fromLat: driverUser.lat, fromLng: driverUser.lng, toLat: customer.Latitude, toLng: customer.Longitude, isLoading: false, showDirection: true, distance: distance.text, duration: duration.text, time: duration.value, showEta: true })
                        }
                    }
                }
            } else {
                this.setState({ isLoading: false });
            }
        }
    }

    render() {
        if (this.state.isLoading) {
            return (
                <div className="h-100 flex-container-vertical flex-center">
                    <div className="spinner m-32">
                        <div className="bounce1 dark"></div>
                        <div className="bounce2 dark"></div>
                        <div className="bounce3 dark"></div>
                    </div>
                </div>
            );
        }
        return (
            <div>
                <Detail
                    customer={this.props.customer}
                    order={this.props.order}
                    distance={this.state.distance}
                    duration={this.state.duration}
                    fromLat={this.state.fromLat}
                    fromLng={this.state.fromLng}
                    toLat={this.state.toLat}
                    toLng={this.state.toLng}
                    showDirection={this.state.showDirection}
                    showEta={this.state.showEta}
                    time={this.state.time}
                    token={this.props.authen.token}
                    grade={this.state.grade}
                />
            </div>
        );
    }
}

const mapStateToProps = (state, props) => {
    return {
        driver: state.driver,
        order: state.orders.find(order => order.JobOrderID === props.match.params.id),
        authen: state.authen,
        customer: state.customer
    }
}

export default connect(mapStateToProps, {
    fetchCustomer,
    fetchDrivers
})(OrderDetail);