import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Modal from 'react-modal';

import { setFilters, fetchNotifications, login, updateNotification } from '../actions'
import Header from './Header';
import Search from './Search';
import tvs from '../api/tvs';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '60%',
        height: '400px'
    }
};

class Notification extends React.Component {
    state = {
        isOpen: false,
        item: {}
    }

    async componentDidMount() {
        if (!this.props.authen.isLogin) {
            const response = await tvs.post('/Authen/UserAuthen', {
                UserName: localStorage.getItem('username'),
                Password: localStorage.getItem('password')
            });
            const { access_token, expired_in, FullName, UserID, UserType, CustomerCode } = response.data;
            this.props.login({
                token: access_token,
                expiredIn: expired_in,
                name: FullName,
                isLogin: true,
                userId: UserID,
                userType: UserType,
                customerCode: CustomerCode
            });
        }
        if (this.props.notifications.length === 0)
            this.props.fetchNotifications();
    }

    componentWillUnmount() {
        this.props.filters.search = '';
        this.props.setFilters({ ...this.props.filters });
    }

    async onReview(item) {
        if (item.IsReview === 'False') {
            var response = await tvs.post('/TVS/PostSystemNotiReview', {
                SysNotiID: item.SysNotiID
            }, {
                headers: {
                    Authorization: `Bearer ${this.props.authen.token}`
                }
            });
            if (response.status === 200 && response.data.status.code === '00') {
                this.props.updateNotification(item);
            }
        }    }

    renderList() {
        const { search } = this.props.filters;
        let filteredList = search !== '' ? this.props.notifications.filter(noti => (noti.MsgTitle != null ? noti.MsgTitle.toString().toLowerCase().includes(search.toString().toLowerCase()) : false) || (noti.MsgValue != null ? noti.MsgValue.toString().toLowerCase().includes(search.toString().toLowerCase()) : false)) : this.props.notifications;
        filteredList.sort((a, b) => new Date(b.CreatedOn) - new Date(a.CreatedOn))
        if (filteredList.length === 0) return <div style={{ textAlign: 'center' }} className="p-48">Notification not found.</div>
        return filteredList.map((item, i) => {
            return (
                <div onClick={() => this.onReview(item)} key={i} style={{ borderBottom: '1px solid #ddd', cursor: 'pointer' }} className={item.IsReview === 'False' ? "flex-container-horizontal flex-center p-16 bg-soft" : "flex-container-horizontal flex-center p-16"}>
                    <div>
                        <div className={item.IsReview === 'False' ? "img-avatar bg-green" : "img-avatar"} style={{ width: '15px', height: '15px' }}></div>
                    </div>
                    <div className="flex-container-vertical mr-16 ml-24 flex-1">
                        <div className="flex-container-horizontal" style={{ alignItems: 'center' }}>
                            <h4>{item.MsgTitle}</h4>
                            <h5 style={{ opacity: '0.6' }} className="ml-32">{moment(item.CreatedOn).format('DD/MM/YYYY')}</h5>
                        </div>
                        <p>{item.MsgValue}</p>
                    </div>
                </div>
            );
        });
    }

    submit = async () => {
        if (this.state.item.IsReview === 'False') {
            var response = await tvs.post('/TVS/PostSystemNotiReview', {
                SysNotiID: this.state.item.SysNotiID
            }, {
                headers: {
                    Authorization: `Bearer ${this.props.authen.token}`
                }
            });
            if (response.status === 200 && response.data.status.code === '00') {
                this.props.updateNotification(this.state.item);
            }
        }
        this.setState({ isOpen: false, item: {} });
    }

    closeModal = () => {
        this.setState({ isOpen: false, item: {} });
    }

    render() {
        return (
            <div className="flex-container-vertical h-100">
                <Header />
                <div className="bg-blue">
                    <div className="flex-container-horizontal pt-24 pl-24" style={{ alignItems: 'center' }}>
                        <i className="material-icons md-light md-36 pv-16">
                            notifications
                        </i>
                        <h3 className="cl-white mv-16">Notifications</h3>
                    </div>
                    <Search />
                </div>
                <div id="reset" className="flex-1 scroll-v" style={{ height: '1px', padding: 0 }}>
                    {this.renderList()}
                </div>
                <Modal
                    isOpen={this.state.isOpen}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                >
                    <div className="flex-container-vertical" style={{ height: '100%' }}>
                        <h3 style={{ textAlign: 'center' }}>{this.state.item.MsgTitle}</h3>
                        <h5 className="m-16 flex-1">{this.state.item.MsgValue}</h5>
                        <div className="flex-container-horizontal flex-center" style={{ justifyContent: 'space-around' }}>
                            <button onClick={() => this.submit()} id="confirm" className="btn">ตกลง</button>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        filters: state.filters,
        notifications: state.notifications,
        authen: state.authen
    }
}

export default connect(mapStateToProps, {
    setFilters,
    fetchNotifications,
    login,
    updateNotification
})(Notification);