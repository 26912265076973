import { FETCH_ORDERS } from '../actions/type';

const INITIAL_STATE = [
    // {
    //     id: 1,
    //     date: '10/09/2019',
    //     orderNo: '118045010',
    //     trackNo: '118045010',
    //     invoiceNo: '118045010',
    //     carNo: 'กด 1190',
    //     driver: 'Smith Liam',
    //     status: 'Order receipt',
    //     eta: '15:00',
    //     distribute: 'บจก. เอส เค พลาสติก'
    // }, {
    //     id: 2,
    //     date: '10/09/2019',
    //     orderNo: '118045011',
    //     trackNo: '118045011',
    //     invoiceNo: '118045011',
    //     carNo: 'นข 1361',
    //     driver: 'สมชาย เรืองแก้ว',
    //     status: 'Order depart',
    //     eta: '5:00',
    //     distribute: 'บจก. เอส เค พลาสติก'
    // }, {
    //     id: 3,
    //     date: '10/09/2019',
    //     orderNo: '118045012',
    //     trackNo: '118045012',
    //     invoiceNo: '118045012',
    //     carNo: 'ฐก 567',
    //     driver: 'สมจิตร หว้งรุ่ง',
    //     status: 'In transit to yard',
    //     eta: '10:00',
    //     distribute: 'บจก. ปิโตรพลัส'
    // }, {
    //     id: 4,
    //     date: '09/09/2019',
    //     orderNo: '118045013',
    //     trackNo: '118045013',
    //     invoiceNo: '118045013',
    //     carNo: 'กด 1190',
    //     driver: 'Smith Liam',
    //     status: 'Completed delivery',
    //     eta: '2.45',
    //     distribute: 'บจก.เอส เค พลาสติก'
    // }, {
    //     id: 5,
    //     date: '09/09/2019',
    //     orderNo: '118045014',
    //     trackNo: '118045014',
    //     invoiceNo: '118045014',
    //     carNo: 'ทห 9987',
    //     driver: 'องอาจ ดาวเสาร์',
    //     status: 'Completed delivery',
    //     eta: '1.30',
    //     distribute: 'บจก.เอส เค พลาสติก'
    // }, {
    //     id: 6,
    //     date: '10/09/2019',
    //     orderNo: '118045011',
    //     trackNo: '118045011',
    //     invoiceNo: '118045011',
    //     carNo: 'นข 1361',
    //     driver: 'สมชาย เรืองแก้ว',
    //     status: 'Order depart',
    //     eta: '5:00',
    //     distribute: 'บจก. เอส เค พลาสติก'
    // }, {
    //     id: 7,
    //     date: '10/09/2019',
    //     orderNo: '118045012',
    //     trackNo: '118045012',
    //     invoiceNo: '118045012',
    //     carNo: 'ฐก 567',
    //     driver: 'สมจิตร หว้งรุ่ง',
    //     status: 'In transit to yard',
    //     eta: '10:00',
    //     distribute: 'บจก. ปิโตรพลัส'
    // }, {
    //     id: 8,
    //     date: '09/09/2019',
    //     orderNo: '118045013',
    //     trackNo: '118045013',
    //     invoiceNo: '118045013',
    //     carNo: 'กด 1190',
    //     driver: 'Smith Liam',
    //     status: 'Completed delivery',
    //     eta: '2.45',
    //     distribute: 'บจก.เอส เค พลาสติก'
    // }, {
    //     id: 9,
    //     date: '09/09/2019',
    //     orderNo: '118045014',
    //     trackNo: '118045014',
    //     invoiceNo: '118045014',
    //     carNo: 'ทห 9987',
    //     driver: 'องอาจ ดาวเสาร์',
    //     status: 'Completed delivery',
    //     eta: '1.30',
    //     distribute: 'บจก.เอส เค พลาสติก'
    // }
]

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_ORDERS:
            return [ ...action.payload ]
        default:
            return state;
    }
}