import React from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';

import history from '../history';
import OrderList from './OrderList';
import Login from './Login';
import OrderDetail from './OrderDetail';
import Notification from './Notification';
import OrderInfo from './OrderInfo';
import Survey from './Survey';
import Survey2 from './Survey2';
import Profile from './Profile';
import SoOrderInfo from './SoOrderInfo';

const App = () => {
    return (
        <div style={{ minHeight: '100vh' }}>
            <HashRouter history={history}>
                <Switch>
                    <Route path="/" exact component={Login} />
                    <Route path="/Orders" exact component={OrderList} />
                    <Route path="/Orders/:id" exact component={OrderDetail} />
                    <Route path="/Notification" exact component={Notification} />
                    <Route path="/OrderInfo/:id" exact component={OrderInfo} />
                    <Route path="/SOOrderInfo/:id" exact component={SoOrderInfo} />
                    <Route path="/Survey/:id" exact component={Survey} />
                    <Route path="/Survey2/:id" exact component={Survey2} />
                    <Route path="/Profile" exact component={Profile} />
                </Switch>
            </HashRouter>
        </div>
    );
}

export default App;