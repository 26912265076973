import { SET_FILTERS, FETCH_ORDERS, FETCH_NOTIFICATIONS, LOGIN, LOGOUT, SET_LOADING, FETCH_CUSTOMER, FETCH_DRIVERS, UPDATE_NOTIFICATION } from './type';
import tvs from '../api/tvs';
import moment from 'moment';
import _ from 'lodash';

export const setFilters = (filters) => {
    return {
        type: SET_FILTERS,
        payload: filters
    }
}

export const fetchOrders = (startDate = moment().format('YYYY-MM-DD'), endDate = moment().format('YYYY-MM-DD')) => async (dispatch, getState) => {
    const { token, userType, customerCode } = getState().authen;
    const response = await tvs.post('/TVS/GetSoListFromCust', {
        UserType: userType,
        CustomerCode: customerCode,
        LoadingPlanFrom: startDate,
        LoadingPlanTo: endDate
    }, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });

    const { ResponseCode, ResponseData } = response.data;
    ResponseData.sODetails.forEach(order => {
        order.orderIndex = order.hashValueDock + order.DoNo;
        order.date = moment(order.tmLoadingDatePlan).format('DD/MM/YYYY');
        order.statusDate = moment(order.ProcessStatusDateTime).format('DD/MM/YYYY hh:mm A');
        order.ProcessStatusDateTime = !!order.ProcessStatusDateTime ? moment(order.ProcessStatusDateTime).format('DD/MM/YYYY hh:mm A').toString() : '';
    });
    dispatch({ type: SET_LOADING, payload: false });
    if (response.status === 200 && ResponseCode === '00') {
        dispatch({ type: FETCH_ORDERS, payload: _.uniqBy(ResponseData.sODetails, 'orderIndex') });
    }
}

export const fetchNotifications = () => async (dispatch, getState) => {
    const { token, userId } = getState().authen;
    const response = await tvs.post('/TVS/GetSystemNotiList', {
        UserID: userId
    }, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    const { ResponseCode, ResponseData } = response.data;
    if (response.status === 200 && ResponseCode === '00') {
        dispatch({ type: FETCH_NOTIFICATIONS, payload: ResponseData.systemNotiList });
    }
}

export const login = (response) => {
    return {
        type: LOGIN,
        payload: response
    }
}

export const logout = () => {
    return {
        type: LOGOUT
    }
}

export const setLoading = (isLoading) => {
    return {
        type: SET_LOADING,
        payload: isLoading
    }
}

export const fetchCustomer = (customerInfo) => {
    return {
        type: FETCH_CUSTOMER,
        payload: customerInfo
    }
}

export const fetchDrivers = (driver) => {
    return {
        type: FETCH_DRIVERS,
        payload: driver
    }
}

export const updateNotification = (item) => {
    return {
        type: UPDATE_NOTIFICATION,
        payload: item
    }
}