import { SET_FILTERS } from '../actions/type';

const INITIAL_STATE = {
    currentPage: 1,
    ordersPerPage: 10,
    search: '',
    sortBy: '',
    sortDesc: false,
    date: [new Date().setDate(new Date().getDate() - 1), new Date()]
}
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_FILTERS:
            return { ...action.payload } 
        default:
            return state;
    }
}
