import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import filtersReducer from './filtersReducer';
import ordersReducer from './ordersReducer';
import notificationsReducer from './notificationsReducers';
import authenReducer from './authenReducers';
import customerReducer from './customerReducer';
import driverReducer from './driverReducer';

export default combineReducers({
    form: formReducer,
    filters: filtersReducer,
    orders: ordersReducer,
    notifications: notificationsReducer,
    authen: authenReducer,
    customer: customerReducer,
    driver: driverReducer
});