import React from 'react';
import { connect } from 'react-redux';

import { subscribeUser } from '../subscription';
import history from '../history';
import logo from '../images/gcl-logo.png';
import { login } from '../actions';
import tvs from '../api/tvs';
import axios from 'axios';
import onequik from '../api/onequik';

class Login extends React.Component {
    state = {
        username: '',
        password: '',
        isLoading: false,
        errorMessage: ''
    }

    async componentDidMount() {
        const url = window.location.href
        if (url.includes('code=')) {
            this.setState({ isLoading: true })
            try {
                const sub = url.indexOf('code=') + 5
                const code = url.substring(sub, url.indexOf('&state='))
                this.getLineAccessToken(code, (token) => {
                    this.getLineProfile(token, async (lineId) => {
                        const authenResponse = await onequik.post('/api-tvsproxy/Authen/SystemAuthen', {
                            SystemID: process.env.REACT_APP_SYSTEM_ID
                        });
                        if (authenResponse.status === 200 && authenResponse.data.access_token !== undefined) {
                            const { access_token } = authenResponse.data;

                            const customerResponse = await onequik.post('/api-tvsproxy/api/GetCustomerDataFromLineID', {
                                LineId: lineId
                            }, {
                                headers: {
                                    Authorization: `Bearer ${access_token}`
                                }
                            });

                            if (customerResponse.data.datas.length > 0) {
                                const { UserType, CompanyCode, uName, FullName } = customerResponse.data.datas[0]

                                if (customerResponse.status === 200) {
                                    this.props.login({
                                        token: access_token,
                                        name: FullName,
                                        isLogin: true,
                                        userId: uName,
                                        userType: UserType,
                                        customerCode: CompanyCode
                                    });
                                    subscribeUser(uName, UserType, CompanyCode);
                                    this.setState({ isLoading: false })
                                    history.push('/Orders');
                                }
                            } else {
                                this.setState({ isLoading: false })
                            }
                        }
                    })
                })
            }
            catch (e) {
                this.setState({ isLoading: false })
            }
        } else {
            const username = localStorage.getItem('username');
            const password = localStorage.getItem('password');
            if (username !== undefined && username !== '' && password !== undefined && password !== '') {
                this.setState({ isLoading: true });
                const response = await tvs.post('/Authen/UserAuthen', {
                    UserName: username,
                    Password: password
                });
                if (response.status === 200 && response.data.access_token !== undefined) {
                    const { access_token, expired_in, FullName, UserID, UserType, CustomerCode } = response.data;
                    this.props.login({
                        token: access_token,
                        expiredIn: expired_in,
                        name: FullName,
                        isLogin: true,
                        userId: UserID,
                        userType: UserType,
                        customerCode: CustomerCode
                    });
                    subscribeUser(UserID, UserType, CustomerCode);
                    history.push('/Orders');
                }
                this.setState({ isLoading: false });
            }
        }
    }

    async getLineAccessToken(code, callback) {
        const params = new URLSearchParams()
        params.append('code', code)
        params.append('redirect_uri', window.location.origin)
        params.append('grant_type', 'authorization_code')
        params.append('client_id', process.env.REACT_APP_CLIENT_ID)
        params.append('client_secret', process.env.REACT_APP_CLIENT_SECRET)

        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }

        let response
        try {
            response = await axios.post('https://api.line.me/oauth2/v2.1/token', params, config)
        } catch (error) {
            console.error(error)
        }

        if (response) {
            const data = { code, ...response.data }
            const isHasToken = data.access_token
            if (isHasToken) {
                callback(isHasToken)
            }
        }
    }

    async getLineProfile(token, callback) {
        const config = {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }

        let response
        try {
            response = await axios.post('https://api.line.me/v2/profile', null, config)
        } catch (error) {
            console.error(error)
        }

        if (response) {
            const data = { ...response.data }
            const isUserId = data.userId
            if (isUserId) {
                callback(isUserId)
            }
        }
    }

    renderButton = () => {
        if (this.state.isLoading) {
            return (
                <div className="spinner m-32">
                    <div className="bounce1"></div>
                    <div className="bounce2"></div>
                    <div className="bounce3"></div>
                </div>
            );
        }
        return (
            <button onClick={() => this.onSubmit()} className="btn-confirm">
                Login
            </button>
        );
    }

    onUsernameChange = (e) => {
        this.setState({ username: e.target.value });
    }

    onPasswordChange = (e) => {
        this.setState({ password: e.target.value });
    }

    onSubmit = async () => {
        if (this.state.username === '' || this.state.password === '') {
            this.setState({ errorMessage: 'Username and Password are required' })
        } else {
            this.setState({ isLoading: true });
            const response = await tvs.post('/Authen/UserAuthen', {
                UserName: this.state.username,
                Password: this.state.password
            });
            if (response.status === 200 && response.data.access_token !== undefined) {
                const { access_token, expired_in, FullName, UserID, UserType, CustomerCode } = response.data;
                this.props.login({
                    token: access_token,
                    expiredIn: expired_in,
                    name: FullName,
                    isLogin: true,
                    userId: UserID,
                    userType: UserType,
                    customerCode: CustomerCode
                });
                subscribeUser(UserID, UserType, CustomerCode);
                history.push('/Orders');
                localStorage.setItem('username', this.state.username);
                localStorage.setItem('password', this.state.password);
            } else {
                this.setState({ errorMessage: response.data.status.message });
            }
            this.setState({ isLoading: false });
        }
    }

    render() {
        return (
            <div id="vertical" >
                <div className="flex-container-horizontal" style={{ justifyContent: 'center', minHeight: '100vh' }}>
                    <div className="flex-container-vertical pv-32" style={{ flexGrow: '5', justifyContent: 'center' }}>
                        <img className="img-logo" alt="" src={logo} />
                        <h2 id="logo-word">Welcome to Transportation Visualization</h2>
                    </div>
                    <div className="bg-blue flex-container-vertical flex-center ph-24 pv-48" style={{ flexGrow: '2' }}>
                        <h2 className="cl-white ph-32" style={{ textAlign: 'center' }}>LOGIN TO YOUR ACCOUNT</h2>
                        <div className="floating-form">
                            <div className="floating-label">
                                <input className="floating-input cl-white" type="text" placeholder=" " value={this.state.username} onChange={(e) => { this.onUsernameChange(e) }} />
                                <label>Username</label>
                            </div>
                        </div>
                        <div className="floating-form">
                            <div className="floating-label">
                                <input className="floating-input cl-white" type="password" placeholder=" " value={this.state.password} onChange={(e) => { this.onPasswordChange(e) }} />
                                <label>Password</label>
                            </div>
                        </div>
                        <h4 className="cl-white">{this.state.errorMessage}</h4>
                        {this.renderButton()}
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(null, {
    login
})(Login);