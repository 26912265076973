import 'date-fns';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import _ from 'lodash';

import { fetchOrders, setFilters, setLoading, login, fetchNotifications } from '../actions';
import Header from './Header';
import Search from './Search';
import Pager from './Pager';
import tvs from '../api/tvs';

class OrderList extends React.Component {
    async componentDidMount() {
        if (!this.props.authen.isLogin) {
            const response = await tvs.post('/Authen/UserAuthen', {
                UserName: localStorage.getItem('username'),
                Password: localStorage.getItem('password')
            });
            const { access_token, expired_in, FullName, UserID, UserType, CustomerCode } = response.data;
            this.props.login({
                token: access_token,
                expiredIn: expired_in,
                name: FullName,
                isLogin: true,
                userId: UserID,
                userType: UserType,
                customerCode: CustomerCode
            });
        }
        this.props.setLoading(true);
        this.props.fetchOrders(moment(this.props.filters.date[0]).format('YYYY-MM-DD'), moment(this.props.filters.date[1]).format('YYYY-MM-DD'));
        this.props.fetchNotifications();
    }

    componentWillUnmount() {
        this.props.filters.search = '';
        this.props.setFilters({ ...this.props.filters });
    }

    fetch() {
        const { orders } = this.props;
        const { search, currentPage, ordersPerPage, sortBy, sortDesc } = this.props.filters;
        const indexOfLast = currentPage * ordersPerPage;
        const indexOfFirst = indexOfLast - ordersPerPage;
        const filteredOrder = search !== '' ? orders.filter(order => (order.tmLoadingDatePlan != null ? order.tmLoadingDatePlan.toString().toLowerCase().includes(search.toString().toLowerCase()) : false) || (order.ci_so != null ? order.ci_so.toString().toLowerCase().includes(search.toString().toLowerCase()) : false) || (order.DoNo != null ? order.DoNo.toString().toLowerCase().includes(search.toString().toLowerCase()) : false) || (order.MaterialDescription != null ? order.MaterialDescription.toString().toLowerCase().includes(search.toString().toLowerCase()) : false) || (order.vehicleLicenseHead != null ? order.vehicleLicenseHead.toString().toLowerCase().includes(search.toString().toLowerCase()) : false) || (order.driverName != null ? order.driverName.toString().toLowerCase().includes(search.toString().toLowerCase()) : false) || (order.ProcessStatusName != null ? order.ProcessStatusName.toString().toLowerCase().includes(search.toString().toLowerCase()) : false) || (order.shipToName != null ? order.shipToName.toString().toLowerCase().includes(search.toString().toLowerCase()) : false) || (order.SoldToName != null ? order.SoldToName.toString().toLowerCase().includes(search.toString().toLowerCase()) : false)) : this.props.orders;
        let sortedOrder = [];
        if (sortBy === 'tmLoadingDatePlan') sortedOrder = sortDesc ? filteredOrder.sort((a, b) => moment(a[sortBy]) > moment(b[sortBy]) ? 1 : -1) : filteredOrder.sort((a, b) => moment(a[sortBy]) < moment(b[sortBy]) ? 1 : -1);
        else sortedOrder = sortDesc ? _.sortBy(filteredOrder, [sortBy]) : _.sortBy(filteredOrder, [sortBy]).reverse();
        const currentOrder = (sortedOrder.slice(indexOfFirst, indexOfLast));
        return currentOrder;
    }

    renderListXl() {
        const currentOrder = this.fetch();
        const { isLoading } = this.props.authen;
        const { currentPage, ordersPerPage } = this.props.filters;
        if (currentOrder.length === 0 && !isLoading) {
            return <div style={{ textAlign: 'center' }} className="p-32">Order not found.</div>
        } else if (currentOrder.length === 0 && isLoading) {
            return (
                <div className="spinner m-32">
                    <div className="bounce1 dark"></div>
                    <div className="bounce2 dark"></div>
                    <div className="bounce3 dark"></div>
                </div>
            );
        }
        return currentOrder.map((order, i) => {
            return (
                <Link key={i} to={`/Orders/${order.JobOrderID}`} id="item" className="grid-container-order-item" style={{ textAlign: 'center' }}>
                    <h5 className="center" style={{ textAlign: 'center' }}>{((currentPage - 1) * ordersPerPage) + (i + 1)}</h5>
                    <h5 className="center" style={{ textAlign: 'center' }}>{moment(order.tmLoadingDatePlan).format('DD/MM/YYYY').toString()}</h5>
                    <h5 className="center" style={{ textAlign: 'center' }}>{order.ci_so}</h5>
                    <h5 className="center" style={{ textAlign: 'center' }}>{order.DoNo}</h5>
                    <h5 className="center" style={{ textAlign: 'center' }}>{order.MaterialDescription}</h5>
                    <h5 className="center" style={{ textAlign: 'center' }}>{order.vehicleLicenseHead}</h5>
                    <h5 className="center" style={{ textAlign: 'center' }}>{order.DriverName}</h5>
                    <h5 style={{ textAlign: 'center' }} className={order.ProcessStatusName === 'ลงสินค้าเสร็จแล้ว' ? 'cl-green ml-1 center' : 'cl-red ml-1 center'}>{order.ProcessStatusName}</h5>
                    <h5 className="center" style={{ textAlign: 'center' }}>{order.SoldToName}</h5>
                    <h5 className="center" style={{ textAlign: 'center' }}>{order.shipToName}</h5>
                </Link>
            );
        });
    }

    renderList() {
        const currentOrder = this.fetch();
        const { isLoading } = this.props.authen;
        const { currentPage, ordersPerPage } = this.props.filters;
        if (currentOrder.length === 0 && !isLoading) {
            return <div style={{ textAlign: 'center' }} className="p-32">Order not found.</div>
        } else if (currentOrder.length === 0 && isLoading) {
            return (
                <div className="spinner m-32">
                    <div className="bounce1 dark"></div>
                    <div className="bounce2 dark"></div>
                    <div className="bounce3 dark"></div>
                </div>
            );
        }
        return currentOrder.map((order, i) => {
            return (
                <Link key={i} to={`/Orders/${order.JobOrderID}`} id="item" className="flex-order-table-item" style={{ textAlign: 'center' }}>
                    <h5 style={{ width: '100px', textAlign: 'center' }}>{((currentPage - 1) * ordersPerPage) + (i + 1)}</h5>
                    <h5 style={{ width: '250px', textAlign: 'center' }}>{moment(order.tmLoadingDatePlan).format('DD/MM/YYYY').toString()}</h5>
                    <h5 style={{ width: '150px', textAlign: 'center' }}>{order.ci_so}</h5>
                    <h5 style={{ width: '150px', textAlign: 'center' }}>{order.DoNo}</h5>
                    <h5 style={{ width: '200px', textAlign: 'center' }}>{order.MaterialDescription}</h5>
                    <h5 style={{ width: '120px', textAlign: 'center' }}>{order.vehicleLicenseHead}</h5>
                    <h5 style={{ width: '200px', textAlign: 'center' }}>{order.DriverName}</h5>
                    <h5 style={{ width: '180px', textAlign: 'center' }} className={order.ProcessStatusName === 'ลงสินค้าเสร็จแล้ว' ? 'cl-green ml-1' : 'cl-red ml-1'}>{order.ProcessStatusName}</h5>
                    <h5 style={{ width: '350px', textAlign: 'center' }}>{order.SoldToName}</h5>
                    <h5 style={{ width: '350px', textAlign: 'center', paddingRight: '24px' }}>{order.shipToName}</h5>
                </Link>
            );
        });
    }

    renderCardList() {
        const currentOrder = this.fetch();
        const { isLoading } = this.props.authen;
        const { currentPage, ordersPerPage } = this.props.filters;
        if (currentOrder.length === 0 && !isLoading) {
            return <div style={{ textAlign: 'center' }} className="m-16 p-48">Order not found.</div>
        }
        else if (currentOrder.length === 0 && isLoading) {
            return (
                <div className="spinner m-32">
                    <div className="bounce1 dark"></div>
                    <div className="bounce2 dark"></div>
                    <div className="bounce3 dark"></div>
                </div>
            );
        }
        return currentOrder.map((order, i) => {
            return (
                <Link key={i} to={`/Orders/${order.JobOrderID}`}>
                    <div className="p-16 card m-8">
                        <div className="flex-card-list">
                            <div>No.: <span style={{ opacity: 0.7 }}>{((currentPage - 1) * ordersPerPage) + (i + 1)}</span></div>
                            <div>วันทีบรรจุสินค้า: <span style={{ opacity: 0.7 }}>{moment(order.tmLoadingDatePlan).format('DD/MM/YYYY').toString()}</span></div>
                            <div>เลขใบสั่งขาย: <span style={{ opacity: 0.7 }}>{order.ci_so}</span></div>
                            <div>เลขที่ใบขนส่ง: <span style={{ opacity: 0.7 }}>{order.DoNo}</span></div>
                            <div>Material Description: <span style={{ opacity: 0.7 }}>{order.MaterialDescription}</span></div>
                            <div>ทะเบียนรถ: <span style={{ opacity: 0.7 }}>{order.vehicleLicenseHead}</span></div>
                            <div>คนขับรถ: <span style={{ opacity: 0.7 }}></span>{order.DriverName}</div>
                            <div>สถานะการขนส่ง: <span className={order.ProcessStatusName === 'ลงสินค้าเสร็จแล้ว' ? 'cl-green' : 'cl-red'} style={{ opacity: 0.7 }}>{order.ProcessStatusName}</span></div>
                            <div>ตัวแทนจำหน่าย: <span style={{ opacity: 0.7 }}>{order.SoldToName}</span></div>
                            <div>สถานที่จัดส่ง: <span style={{ opacity: 0.7 }}>{order.shipToName}</span></div>
                        </div>
                    </div>
                </Link>
            );
        });
    }

    handleSort(sort) {
        if (this.props.filters.sortBy === sort) {
            this.props.filters.sortDesc = !this.props.filters.sortDesc;
            this.props.setFilters({ ...this.props.filters });
        } else {
            this.props.filters.sortBy = sort;
            this.props.setFilters({ ...this.props.filters });
        }
    }

    onChange = date => {
        this.props.filters.date = date;
        this.props.setFilters({ ...this.props.filters });
        this.props.fetchOrders(moment(date[0]).format('YYYY-MM-DD'), moment(date[1]).format('YYYY-MM-DD'));
    }

    render() {
        return (
            <div className="flex-container-vertical h-100">
                <Header />
                <div className="bg-blue">
                    <div className="flex-container-horizontal datepicker-container">
                        <div className="flex-container-horizontal">
                            <i id="invisible" className="material-icons md-light md-36">
                                shopping_basket
                            </i>
                            <h3 id="invisible" className="cl-white mv-24">Orders</h3>
                        </div>
                        <div className="datepicker mv-16">
                            <DateRangePicker
                                onChange={this.onChange}
                                value={this.props.filters.date}
                                format={"dd/MM/y"}
                            />
                        </div>
                    </div>
                    <Search />
                </div>
                <div className="flex-container-vertical flex-1" id="visible-flex" style={{ padding: '0 0 0 0' }}>
                    <div className="scroll-v flex-1" style={{ height: '1px' }}>
                        {this.renderCardList()}
                    </div>
                </div>
                <div id="invisible" className="flex-container-vertical card flex-1 media-l" style={{ padding: '0 0 0 0', overflowX: 'scroll' }}>
                    <div className="scroll-v flex-1 center" style={{ height: '1px' }}>
                        <div id="header" className="flex-order-table">
                            <div style={{ width: '100px' }} className="center">No.</div>
                            <div style={{ width: '250px' }} onClick={e => this.handleSort('tmLoadingDatePlan')} className="center">วันที่บรรจุสินค้า</div>
                            <div style={{ width: '150px' }} onClick={e => this.handleSort('ci_so')} className="center">เลขใบสั่งขาย</div>
                            <div style={{ width: '150px' }} onClick={e => this.handleSort('DoNo')} className="center">เลขที่ใบขนส่ง</div>
                            <div style={{ width: '200px' }} onClick={e => this.handleSort('MaterialDescription')} className="center">Material Description</div>
                            <div style={{ width: '120px' }} onClick={e => this.handleSort('vehicleLicenseHead')} className="center">ทะเบียนรถ</div>
                            <div style={{ width: '200px' }} onClick={e => this.handleSort('DriverName')} className="center">คนขับรถ</div>
                            <div style={{ width: '180px' }} onClick={e => this.handleSort('ProcessStatusName')} className="center">สถานะการขนส่ง</div>
                            <div style={{ width: '350px' }} onClick={e => this.handleSort('SoldToName')} className="center">ตัวแทนจำหน่าย</div>
                            <div style={{ width: '350px', paddingRight: '24px' }} onClick={e => this.handleSort('shipToName')} className="center">สถานที่จัดส่ง</div>
                        </div>
                        {this.renderList()}
                    </div>
                </div>
                <div className="flex-container-vertical flex-1 media-xl">
                    <div id="header" className="grid-container-order-list">
                        <div className="center">No.</div>
                        <div onClick={e => this.handleSort('tmLoadingDatePlan')} className="center">วันที่บรรจุสินค้า</div>
                        <div onClick={e => this.handleSort('ci_so')} className="center">เลขใบสั่งขาย</div>
                        <div onClick={e => this.handleSort('DoNo')} className="center">เลขที่ใบขนส่ง</div>
                        <div onClick={e => this.handleSort('MaterialDescription')} className="center">Material Description</div>
                        <div onClick={e => this.handleSort('vehicleLicenseHead')} className="center">ทะเบียนรถ</div>
                        <div onClick={e => this.handleSort('DriverName')} className="center">คนขับรถ</div>
                        <div onClick={e => this.handleSort('ProcessStatusName')} className="center">สถานะการขนส่ง</div>
                        <div onClick={e => this.handleSort('SoldToName')} className="center">ตัวแทนจำหน่าย</div>
                        <div onClick={e => this.handleSort('shipToName')} className="center">สถานที่จัดส่ง</div>
                    </div>
                    <div className="flex-1" style={{ overflowY: 'scroll', height: '1px' }}>
                        {this.renderListXl()}
                    </div>
                </div>
                <Pager />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        orders: state.orders,
        filters: state.filters,
        authen: state.authen
    }
}
export default connect(mapStateToProps, {
    fetchOrders,
    setFilters,
    setLoading,
    login,
    fetchNotifications
})(OrderList);

