import React from 'react';

import service from '../api/service';
import gps from '../api/gps';
import tvs from '../api/tvs';
import Detail from './Detail';

class SoOrderInfo extends React.Component {
    state = {
        time: 0,
        fromLat: null,
        fromLng: null,
        toLat: null,
        toLng: null,
        showDirection: false,
        isLoading: true,
        distance: '',
        duration: '',
        showEta: false,
        order: {},
        customer: {},
        driver: [],
        expired: false,
        token: null,
        grade: []
    }

    async componentDidMount() {
        const hash = decodeURIComponent(this.props.match.params.id);
        const authenResponse = await tvs.post('/Authen/SystemAuthen', {
            SystemID: process.env.REACT_APP_SYSTEM_ID
        });
        if (authenResponse.status === 200 && authenResponse.data.access_token !== undefined) {
            const { access_token } = authenResponse.data;
            const orderResponse = await tvs.post('/TVS/GetSODetailsFromHash', {
                hashParams: hash
            });
            if (orderResponse.status === 200 && orderResponse.data.ResponseData.sODetails !== undefined) {
                this.setState({ order: orderResponse.data.ResponseData.sODetails[0] });
                const response = await gps.get(`/get_devices?lang=en&user_api_hash=$2y$10$M7lEukYG.deGQH40WeS2/.pc8Sp9yCvinJX8l6trXzUNUVERG8Isq`);
                if (response.status === 200) {
                    this.setState({ driver: response.data });
                }
                const customer = await tvs.post('/TVS/GetCustomer', { CustomerCode: this.state.order.shipToCode }, {
                    headers: { Authorization: `Bearer ${access_token}` }
                });
                const { ResponseCode, ResponseData } = customer.data;
                if (customer.status === 200 && ResponseCode === '00') {

                    this.setState({ customer: ResponseData.ResCustomerInfo[0], token: access_token });
                    const { driver, customer, order } = this.state;
                    const driverUsers = driver.find(user => user.items.find(item => item.name === order.vehicleLicenseHead));
                    const grade = await tvs.post('/TVS/GetGradeList', { hashValue: order.hashValueDock }, {
                        headers: { Authorization: `Bearer ${access_token}` }
                    })
                    if (grade.status === 200 && grade.data.ResponseCode === '00') {
                        this.setState({ grade: grade.data.ResponseData.gradeList });
                    }
                    if (driverUsers === undefined && customer !== undefined) {
                        this.setState({ toLat: customer.Latitude, toLng: customer.Longitude, isLoading: false });
                    } else if(driverUsers !== undefined && customer !== undefined){
                        const driverUser = driverUsers.items.find(driver => driver.name === order.vehicleLicenseHead);
                        if (Number(driverUser.lat) === 0 || Number(driverUser.lng) === 0 || Number(customer.Latitude) === 0 || Number(customer.Longitude) === 0) {
                            if (driverUser.lat !== undefined && driverUser.lat !== null && Number(driverUser.lat) !== 0) {
                                this.setState({ fromLat: driverUser.lat, fromLng: driverUser.lng });
                            } else if (customer.Latitude !== undefined && customer.Latitude !== null && Number(customer.Latitude) !== 0) {
                                this.setState({ toLat: customer.Latitude, toLng: customer.Longitude });
                            }
                            this.setState({ isLoading: false, showDirection: true, showEta: true })
                        } else {
                            var matrix = await service.post('/distance', {
                                fromLat: driverUser.lat,
                                fromLng: driverUser.lng,
                                toLat: customer.Latitude,
                                toLng: customer.Longitude
                            });
                            if (matrix.status === 200) {
                                const { duration, distance } = matrix.data.rows[0].elements[0];
                                this.setState({ fromLat: driverUser.lat, fromLng: driverUser.lng, toLat: customer.Latitude, toLng: customer.Longitude, isLoading: false, showDirection: true, distance: distance.text, duration: duration.text, time: duration.value, showEta: true })
                            }
                        }
                    } else {
                        this.setState({ isLoading: false, showDirection: false, showEta: false })
                    }
                } else {
                    this.setState({ isLoading: false });
                }
            } else {
                this.setState({ expired: true });
            }
        }
    }
    render() {
        if (this.state.isLoading && !this.state.expired) {
            return (
                <div className="h-100 flex-container-vertical flex-center">
                    <div className="spinner m-32">
                        <div className="bounce1 dark"></div>
                        <div className="bounce2 dark"></div>
                        <div className="bounce3 dark"></div>
                    </div>
                </div>
            );
        } else if (this.state.expired) {
            return (
                <div className="h-100 flex-container-vertical flex-center">
                    <h5>Hash has Expired.</h5>
                </div>
            );
        }
        return (
            <div>
                <Detail
                    customer={this.state.customer}
                    order={this.state.order}
                    distance={this.state.distance}
                    duration={this.state.duration}
                    fromLat={this.state.fromLat}
                    fromLng={this.state.fromLng}
                    toLat={this.state.toLat}
                    toLng={this.state.toLng}
                    showDirection={this.state.showDirection}
                    showEta={this.state.showEta}
                    hash={true}
                    time={this.state.time}
                    token={this.state.token}
                    grade={this.state.grade}
                />
            </div>
        );
    }
}
export default SoOrderInfo