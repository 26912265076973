import React from 'react';
import { connect } from 'react-redux';

import { setFilters } from '../actions';

class Search extends React.Component {
    onSearchChange(e) {
        this.props.filters.search = e.target.value
        this.props.setFilters(this.props.filters);
    }

    render() {
        return (
            <div>
                <input value={this.props.filters.search} onChange={e => this.onSearchChange(e)} className="input-search" type="text" placeholder="Search"/>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        filters: state.filters
    }
}
export default connect(mapStateToProps, {
    setFilters
})(Search);