import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import Badge from '@material-ui/core/Badge';

import history from '../history';
import logo from '../images/gcl-logo.png';
import user from '../images/user.png';
import { logout } from '../actions';

Modal.setAppElement('#modal')

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};

const Header = (props) => {
    const [isOpen, setOpen] = useState(false);

    const handleLogout = () => {
        localStorage.setItem('username', undefined);
        localStorage.setItem('password', undefined);
        props.logout();
        history.push('/');
    }

    const openModal = () => {
        setOpen(true);
    }

    const closeModal = () => {
        setOpen(false);
    }

    const profile = () => {
        history.push('/Profile');
    }

    const count = props.notifications.filter(item => item.IsReview === 'False');

    return (
        <div className="toolbar flex-container-horizontal" style={{ alignItems: 'center', justifyContent: 'space-between' }}>
            <div className="flex-container-horizontal" style={{ alignItems: 'center' }}>
                <img className="logo" alt="" src={logo} />
                <Link id="menu-icon" to="/Orders" className="mv-16"><div className="flex-container-horizontal flex-center">
                    <i className="material-icons md-dark md-24">
                        shopping_basket
                    </i>
                    <span className="ml-16" id="invisible">ORDERS</span></div>
                </Link>
                <Link id="menu-icon" to="/Notification" className="mv-16"><div className="flex-container-horizontal flex-center">
                    <Badge badgeContent={count !== undefined ? count.length : 0} color="secondary">
                        <i className="material-icons md-dark md-24">
                            notifications
                        </i>
                    </Badge>
                    <span className="ml-16" id="invisible">NOTIFICATIONS</span>
                </div>
                </Link>
            </div>
            <div className="flex-container-horizontal" style={{ alignItems: 'center', justifyContent: 'flex-end' }}>
                <div style={{ cursor: 'pointer', alignItems: 'center', justifyContent: 'flex-end' }} onClick={profile} className="flex-container-horizontal tooltip">
                    <span className="tooltiptext">Edit Profile</span>
                    <img style={{ width: '12%' }} alt="avatar" src={user} className="img-avatar mr-24 avatar" />
                    <h4 className="mr-8 avatar-name" >{props.authen.name}</h4>
                </div>
                <div style={{ cursor: 'pointer' }} id="invisible" onClick={openModal} className="cl-red mv-16">Logout</div>
                <div id="visible" onClick={openModal} className="cl-red">
                    <i className="material-icons md-dark md-24 center">
                        exit_to_app
                    </i>
                </div>
            </div>
            <Modal
                isOpen={isOpen}
                onRequestClose={closeModal}
                style={customStyles}
            >
                {/* <h2 ref={subtitle => this.subtitle = subtitle}>Hello</h2> */}
                <h3 style={{ textAlign: 'center' }}>ออกจากระบบ</h3>
                <h5 className="m-16">ต้องการออกจากระบบใช่หหรือไม่ ?</h5>
                <div className="flex-container-horizontal flex-center" style={{ justifyContent: 'space-around' }}>
                    <button onClick={() => closeModal()} id="reject" className="btn">ไม่</button>
                    <button onClick={() => handleLogout()} id="confirm" className="btn">ใช่</button>
                </div>
            </Modal>
        </div>

    );
}

const mapStateToProps = (state) => {
    return {
        authen: state.authen,
        notifications: state.notifications
    }
}
export default connect(mapStateToProps, {
    logout
})(Header);