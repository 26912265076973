import React from 'react';
import { connect } from 'react-redux';
import { CSVLink } from "react-csv";
import _ from 'lodash';
import moment from 'moment';

import { setFilters } from '../actions';

class Pager extends React.Component {
    componentDidMount() {
        this.headers = [
            { label: "No.", key: "no" },
            { label: "วันที่บรรจุสินค้า", key: "date" },
            { label: "เลขที่ใบสั่งขาย", key: "ci_so" },
            { label: "เลขที่ใบขนส่ง", key: "DoNo" },
            { label: "Material Description", key: "MaterialDescription" },
            { label: "ทะเบียนรถ", key: "vehicleLicenseHead" },
            { label: "ประเภทรถ", key: "truckType" },
            { label: "คนขับรถ", key: "DriverName" },
            { label: "สถานะการขนส่ง", key: "ProcessStatusName" },
            { label: "วันที่สถานะล่าสุด", key: "statusDate" },
            { label: "ตัวแทนจำหน่าย", key: "SoldToName" },
            { label: "สถานที่จัดส่ง", key: "shipToName" },
            { label: "จำนวนคนติดรถ", key: "Labour" },
            { label: "ที่อยู่ลูกค้า", key: "shipToAddress" }
        ];
    }
    onItemChange(e) {
        this.props.filters.ordersPerPage = e.target.value;
        this.props.setFilters(this.props.filters);
    }

    onChevronLeftClick(e) {
        const { currentPage } = this.props.filters;
        if (currentPage > 1) {
            this.props.filters.currentPage -= 1;
            this.props.setFilters(this.props.filters);
        }
    }

    onChevronRightClick(e) {
        this.props.filters.currentPage += 1;
        this.props.setFilters(this.props.filters);
    }

    render() {
        const { orders } = this.props;
        const { search, currentPage, ordersPerPage } = this.props.filters;
        // let sortedOrder = [];
        // if(sortBy === 'tmLoadingDatePlan') sortedOrder = sortDesc ? orders.sort((a, b) => moment(a[sortBy]) > moment(b[sortBy]) ? 1 : -1) : orders.sort((a, b) => moment(a[sortBy]) < moment(b[sortBy]) ? 1 : -1);
        // else sortedOrder = sortDesc ? _.sortBy(orders, [sortBy]) : _.sortBy(orders, [sortBy]).reverse();
        // const sortedOrder = sortDesc ? orders.sort((a, b) => (a[sortBy] > b[sortBy]) ? 1 : -1) : orders.sort((a, b) => (a[sortBy] < b[sortBy]) ? 1 : -1);
        const currentOrder = search !== '' ? orders.filter(order => (order.tmLoadingDatePlan != null ? order.tmLoadingDatePlan.toString().toLowerCase().includes(search.toString().toLowerCase()) : false) || (order.ci_so != null ? order.ci_so.toString().toLowerCase().includes(search.toString().toLowerCase()) : false) || (order.DoNo != null ? order.DoNo.toString().toLowerCase().includes(search.toString().toLowerCase()) : false) || (order.grade != null ? order.grade.join(', ').toString().toLowerCase().includes(search.toString().toLowerCase()) : false) || (order.vehicleLicenseHead != null ? order.vehicleLicenseHead.toString().toLowerCase().includes(search.toString().toLowerCase()) : false) || (order.driverName != null ? order.driverName.toString().toLowerCase().includes(search.toString().toLowerCase()) : false) || (order.ProcessStatusName != null ? order.ProcessStatusName.toString().toLowerCase().includes(search.toString().toLowerCase()) : false) || (order.shipToName != null ? order.shipToName.toString().toLowerCase().includes(search.toString().toLowerCase()) : false) || (order.SoldToName != null ? order.SoldToName.toString().toLowerCase().includes(search.toString().toLowerCase()) : false)) : this.props.orders;
        currentOrder.forEach((order, i) => { 
           order.no = i + 1;
        });
        const page = ordersPerPage * currentPage;
        return (
            <div className="flex-container-horizontal flex-center p-16" style={{ justifyContent: 'space-between' }}>
                <CSVLink data={currentOrder} headers={this.headers} filename={"CustomerView_Report.csv"}>
                    <button className="btn-export">
                        <div className="flex-container-horizontal flex-center">
                            <i className="material-icons md-green md-24 center">
                                move_to_inbox
                            </i>
                            <span id="invisible" className="cl-green pl-8">Export to Excel</span>
                        </div>
                    </button>
                </CSVLink>
                <div className="flex-container-horizontal flex-center">
                    <div id="invisible" className="mr-16">Row per Page</div>
                    <div id="visible" className="mr-16">Show</div>
                    <select onChange={e => this.onItemChange(e)} className="select-css">
                        <option>10</option>
                        <option>20</option>
                        <option>30</option>
                        <option>40</option>
                        <option>50</option>
                    </select>
                    <div id="invisible" className="pv-24">{`${ordersPerPage * (currentPage - 1) + 1} - ${page}`}</div>
                    <i onClick={e => this.onChevronLeftClick(e)} className="pointer material-icons md-dark md-36 ml-8">
                        chevron_left
                </i>
                    <i onClick={e => this.onChevronRightClick(e)} className="pointer material-icons md-dark md-36">
                        chevron_right
                </i>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        filters: state.filters,
        orders: state.orders
    }
}

export default connect(mapStateToProps, {
    setFilters
})(Pager);