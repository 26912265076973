import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    DirectionsRenderer,
    Marker
} from "react-google-maps";

import Header from './Header';
import customer from '../images/customer.png';
import driver from '../images/driver.png';
import { compose, withProps, lifecycle } from "recompose";

const MapWithADirectionsRenderer = compose(
    withProps({
        googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}&v=3.exp&libraries=geometry,drawing,places`,
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `100%` }} />,
        mapElement: <div style={{ height: `100%` }} />,
    }),
    withScriptjs,
    withGoogleMap,
    lifecycle({
        componentDidMount() {
            const { fromLat, fromLng, toLat, toLng } = this.props;
            const DirectionsService = new window.google.maps.DirectionsService();

            DirectionsService.route({
                origin: new window.google.maps.LatLng(fromLat, fromLng),
                destination: new window.google.maps.LatLng(toLat, toLng),
                travelMode: window.google.maps.TravelMode.DRIVING,
            }, (result, status) => {
                if (status === window.google.maps.DirectionsStatus.OK) {
                    this.setState({
                        directions: result,
                    });
                } else {
                    console.error(`error fetching directions ${result}`);
                }
            });
        }
    })
)(props =>
    <GoogleMap
        defaultZoom={7}
        defaultCenter={new window.google.maps.LatLng(13.7248936, 100.4930264)}
    >
        {props.directions && <div><DirectionsRenderer directions={props.directions} options={{ suppressMarkers: true }} /><Marker position={{ lat: props.fromLat, lng: props.fromLng }} options={{ icon: driver }} /><Marker position={{ lat: props.toLat, lng: props.toLng }} options={{ icon: customer }} /></div>}
    </GoogleMap>
);

class Detail extends React.Component {
    state = {
        showEta: this.props.showEta,
        fromLat: this.props.fromLat,
        fromLng: this.props.fromLng,
        toLat: this.props.toLat,
        toLng: this.props.toLng
    }
    componentDidMount() {
        const { order } = this.props;
        if (order.ProcessStatusDateTime !== null) {
            order.ProcessStatusDateTime = moment(order.ProcessStatusDateTime).format('DD/MM/YYYY HH:mm A');
            const dateTime = order.ProcessStatusDateTime.split(' ');
            const date = dateTime[0].split('/');
            const time = dateTime[1].split(':')
            const processDateTime = new Date(date[2], Number(date[1]) - 1, date[0], time[0], time[1], 0, 0);
            if ((order.ProcessStatusName === 'ลงสินค้าเสร็จแล้ว' || order.ProcessStatusName === 'คืนตู้') && (new Date() > processDateTime)) {
                this.setState({ showEta: false, fromLat: 12.6839192, fromLng: 101.1227092 });
            } else if (order.ProcessStatusName !== 'ลงสินค้าเสร็จแล้ว' && order.ProcessStatusName !== 'คืนตู้' && (new Date() > moment(processDateTime).add(1, 'days'))) {
                this.setState({ showEta: false, fromLat: undefined, fromLng: undefined });
            }
        }
    }
    numberWithCommas(x) {
        x = x.toString();
        var pattern = /(-?\d+)(\d{3})/;
        while (pattern.test(x))
            x = x.replace(pattern, "$1,$2");
        return x;
    }
    renderMap() {
        if (!this.props.showDirection) {
            return (
                <div style={{ height: '50vh', width: '100%' }}>
                    <MapWithADirectionsRenderer directions toLat={Number(this.props.toLat)} toLng={Number(this.props.toLng)} />
                </div>
            );
        } else {
            return (
                <div style={{ height: '50vh', width: '100%' }}>
                    <MapWithADirectionsRenderer directions fromLat={this.props.fromLat} fromLng={this.props.fromLng} toLat={Number(this.props.toLat)} toLng={Number(this.props.toLng)} />
                </div>
            );
        }
    }
    render() {
        const { customer, order } = this.props;
        return (
            <div>
                {!this.props.hash &&
                    <Header />
                }
                <div className="bg-blue" style={{ minHeight: '250px', maxHeight: '250px' }}>
                    {!this.props.hash &&
                        <Link to="/Orders">
                            <div className="flex-container-horizontal pt-24 pl-24" style={{ alignItems: 'center' }}>
                                <i className="material-icons md-light md-24">
                                    keyboard_backspace
                                </i>
                                <h4 className="cl-white mv-24">Orders</h4>
                            </div>
                        </Link>
                    }
                    <div className="flex-container-horizontal pt-24 pl-48" style={{ alignItems: 'center' }}>
                        <i className="material-icons md-light md-24">
                            shopping_basket
                        </i>
                        <h3 className="cl-white mv-24">Order Detail No. {order.DoNo}</h3>
                    </div>
                    <h4 id="detail-text" className="cl-white pt-24 pl-48 pr-48">{`ชื่อคนขับรถ ${order.DriverName}, ประเภทรถ ${order.truckType}, จำนวนคนติดรถ ${order.Labour} คน`}</h4>
                    <div id="reset" className="card m-32 p-32" style={{ minHeight: '100%' }}>
                        <div className="flex-container-horizontal mb-16" style={{ alignItems: 'center' }}>
                            <i className="material-icons md-dark md-36">
                                person
                            </i>
                            <h3 className="ml-24">Customer</h3>
                        </div>
                        <div id="visible-flex" className="flex-container-horizontal mb-16">
                            <div style={{ flexGrow: 1 }}></div>
                            <div style={{ flexGrow: 1, opacity: '0.7' }}>
                                <div>Company</div>
                                <div>Email</div>
                                <div>Phone</div>
                            </div>
                            <div className="pl-8" style={{ flexGrow: 1 }}>
                                <div className="ml-1">{customer !== undefined && customer.CompanyName}</div>
                                <div className="ml-1">{customer !== undefined && customer.Email}</div>
                                <div className="ml-1">{customer !== undefined && customer.Phone}</div>
                            </div>
                        </div>
                        <div id="invisible" className="grid-container-customer-detail-three">
                            <div style={{ textAlign: 'center' }}>Company</div>
                            <div style={{ textAlign: 'center' }}>Email</div>
                            <div style={{ textAlign: 'center' }}>Phone</div>
                        </div>
                        <div id="invisible" className="grid-container-customer-detail-three">
                            <div style={{ textAlign: 'center' }}>{customer !== undefined && customer.CompanyName}</div>
                            <div style={{ textAlign: 'center' }}>{customer !== undefined && customer.Email}</div>
                            <div style={{ textAlign: 'center' }}>{customer !== undefined && customer.Phone}</div>
                        </div>
                        <div id="reset" className="card m-24 p-24">
                            <h4>Shipping Address</h4>
                            <h5 className="m-16">{order.shipToAddress}</h5>
                            {this.renderMap()}
                            {this.props.showEta &&
                                <div className="mt-8" style={{ textAlign: 'right' }}>
                                    ETA: ระยะทาง {this.props.distance.replace('km', 'กม.')} ใช้เวลา {this.props.duration.replace('mins', 'นาที').replace('hours', 'ชั่วโมง')} จะถึงจุดหมายเวลา {moment().add(this.props.time, 'seconds').format('hh:mm A')}
                                </div>
                            }
                        </div>
                        <div className="flex-container-horizontal mb-16 mt-32" style={{ alignItems: 'center' }}>
                            <i className="material-icons md-dark md-36">
                                access_time
                            </i>
                            <h3 className="ml-24">Order Status</h3>
                        </div>
                        <div id="header" className="grid-container-customer-detail">
                            <div>Status</div>
                            <div>Status Date</div>
                        </div>
                        <div id="item" className="grid-container-customer-detail">
                            <div className="cl-green">{order.ProcessStatusName}</div>
                            <div>{order.ProcessStatusDateTime}</div>
                        </div>
                        <div className="flex-container-horizontal mb-16 mt-32" style={{ alignItems: 'center' }}>
                            <i className="material-icons md-dark md-36">
                                assignment
                            </i>
                            <h3 className="ml-24">Grade</h3>
                        </div>
                        <div id="header" className="grid-container-customer-detail">
                            <div>Material Description</div>
                            <div>Qty</div>
                        </div>
                        {this.props.grade.map(item => {
                            const split = item.qtyuom.split(" ");
                            return (
                                <div id="item" className="grid-container-customer-detail">
                                    <div>{item.materialDescription}</div>
                                    <div>{`${this.numberWithCommas(split[0])} ${split[1]}`}</div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    }

}

export default Detail;