import React from 'react';
import { connect } from 'react-redux';

import history from '../history';
import Header from '../components/Header';
import tvs from '../api/tvs';

class Profile extends React.Component {
    state = {
        customer: {},
        email: '',
        phone: ''
    }
    async componentDidMount() {
        if (!this.props.authen.isLogin) {
            history.push('/Orders')
        }
        const customer = await tvs.post('/TVS/GetCustomer', { CustomerCode: this.props.authen.customerCode }, {
            headers: { Authorization: `Bearer ${this.props.authen.token}` }
        });
        const data = customer.data.ResponseData.ResCustomerInfo[0];
        this.setState({ customer: data, email: data.Email === null ? '' : data.Email, phone: data.Phone === null ? '' : data.Phone });
    }

    onEmailChange = (e) => {
        this.setState({ email: e.target.value });
    }
    
    onPhoneChange = (e) => {
        this.setState({ phone: e.target.value });
    }

    onSubmit = async() => {
        const response = await tvs.post('/TVS/PostUpdateCompany', { 
            CompanyCode: this.props.authen.customerCode,
            Phone: this.state.phone,
            Email: this.state.email
        }, {
            headers: { Authorization: `Bearer ${this.props.authen.token}` }
        });    
        if(response.status === 200) {
            history.push('/Orders');
        }
    }

    render() {
        return (
            <div className="flex-container-vertical h-100">
                <Header />
                <div className="bg-blue pt-24 pb-16">
                    <div className="flex-container-horizontal pl-24" style={{ alignItems: 'center' }}>
                        <i className="material-icons md-light md-36 pv-16">
                            person
                        </i>
                        <h3 className="cl-white mv-16">Edit Profile</h3>
                    </div>
                    <div className="flex-container-vertical flex-center">
                        <h3 className="mr-8 avatar-name cl-white mt-8" >{this.props.authen.name}</h3>
                    </div>
                </div>
                <div id="reset" className="flex-1 scroll-v" style={{ height: '1px', padding: 0, }}>
                   <div className="flex-container-vertical flex-center">
                            <span className="mt-24 mb-8">Email</span>
                            <input style={{ width: '60%' }} className="ml-16" value={this.state.email} onChange={e => this.onEmailChange(e)} type="text"/>
                            <span className="mt-24 mb-8">Phone</span>
                            <input style={{ width: '60%' }} className="ml-16" value={this.state.phone} onChange={e => this.onPhoneChange(e)} type="number"/>
                            <button style={{ width: '60%' }} onClick={() => this.onSubmit()} className="btn mt-48" id="confirm">Update</button>
                   </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        authen: state.authen
    }
}
export default connect(mapStateToProps)(Profile);