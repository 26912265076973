import React from 'react';
import Modal from 'react-modal';

import tvs from '../api/tvs';

Modal.setAppElement('#modal')

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};

class Survey extends React.Component {
    state = {
        isLoading: false,
        expired: false,
        survey: [],
        token: '',
        isOpen: false,
        count: 0
    }

    async componentDidMount() {
        const hash = decodeURIComponent(this.props.match.params.id);
        const survey = await tvs.post('/TVS/GetSurveyByHash', {
            hashParams: hash
        });
        if (survey.status === 200 && survey.data.status === undefined) {
            const authenResponse = await tvs.post('/Authen/SystemAuthen', {
                SystemID: process.env.REACT_APP_SYSTEM_ID
            });
            if (authenResponse.status === 200 && authenResponse.data.access_token !== undefined) {
                const { access_token } = authenResponse.data;
                survey.data.surveys.forEach(item => {
                    item.SurveyAnswerChoice = null;
                    item.SurveyAnswerInput = null;
                })
                const config = await tvs.post('/EPOD/GetSystemConfig', {
                    ConfCode: '2002'
                }, {
                    headers: {
                        Authorization: `Bearer ${access_token}`
                    }
                });
                this.setState({ survey: survey.data.surveys, token: access_token, count: Number(config.data.ResponseData.SystemConfValue) });
            }
        } else {
            this.setState({ expired: true });
        }
    }

    onCheckBoxChange(e, i) {
        let obj = this.state.survey;
        if (obj[i].AnswerType === 'C') {
            obj[i].SurveyAnswerChoice = e.target.value;
            this.setState({ survey: obj });
        }
    }

    onInputChange(e) {
        let obj = this.state.survey;
        if (obj[e.target.name].AnswerType === 'T') {
            obj[e.target.name].SurveyAnswerInput = e.target.value;
            this.setState({ survey: obj });
        }
    }

    renderRadio(index) {
        let radioGroup = [];
        for (let i = 0; i < this.state.count; i++) {
            radioGroup.push(<div key={i} className="flex-container-vertical flex-center">
                <div className="mb-8">{i + 1}</div>
                <input type="radio" name={`radio${index}`} onChange={e => this.onCheckBoxChange(e, index)} value={i + 1} />
            </div>);
        }

        return radioGroup;
    }

    selectChange = (e, i) => {
        let obj = this.state.survey;
        if (obj[i].AnswerType === 'D') {
            obj[i].SurveyAnswerInput = e.target.value;
            this.setState({ survey: obj });
        }
    }

    renderSurvey() {
        return this.state.survey.map((item, i) => {
            return (
                <div key={i} className="flex-container-vertical m-24">
                    <h4>
                        {((i + 1 === 1) || (i + 1 === 2) || (i + 1 === 3) || (i + 1 === 4)) && '* '}{i + 1}. {item.SurveyName}
                    </h4>
                    <div className="flex-container-horizontal mt-16" style={{ justifyContent: 'space-around' }}>
                        {item.AnswerType === 'C' && <div id="invisible" style={{ textAlign: 'center' }}>ความพึงพอใจ<br />น้อยที่สุด</div>}
                        {item.AnswerType === 'C' && this.renderRadio(i)}
                        {item.AnswerType === 'T' && <textarea name={i} value={item.SurveyAnswerInput} onChange={e => this.onInputChange(e)} />}
                        {(item.AnswerType === 'D' && item.SurveyName === 'พขร/แรงงานขนถ่าย') &&
                            <select onChange={e => this.selectChange(e, i)} className="select-survey">
                                <option>-- กรุณาเลือก --</option>
                                <option>เดินทางไปส่งสินค้าสาย</option>
                                <option>ส่งสินค้าผิดที่</option>
                                <option>แรงงานขนถ่ายไม่ครบ</option>
                                <option>ไม่ปฏิบัติตามกฎระเบียบ</option>
                                <option>พฤติกรรมไม่สุภาพ (วาจา/กิริยา)</option>
                            </select>}
                        {(item.AnswerType === 'D' && item.SurveyName === 'สินค้า') &&
                            <select onChange={e => this.selectChange(e, i)} className="select-survey">
                                <option>-- กรุณาเลือก --</option>
                                <option>สินค้าเอียง</option>
                                <option>สินค้าเปียกน้ำ</option>
                                <option>สินค้าปนเปื้อน</option>
                                <option>สินค้าไม่ครบ/สูญหาย</option>
                                <option>สินค้าแตก</option>
                                <option>ส่งสินค้าผิด glade/lot</option>
                                <option>พาเลทแตกเสียหาย</option>
                            </select>}
                        {item.AnswerType === 'C' && <div id="invisible" style={{ textAlign: 'center' }}>ความพึงพอใจ<br />มากที่สุด</div>}
                    </div>
                </div>
            );
        })
    }

    async onSubmit() {
        let survey = [];
        for (let i = 0; i < this.state.survey.length; i++) {
            const item = this.state.survey[i];
            if ((item.AnswerType === 'T' && item.SurveyAnswerInput !== null) || (item.AnswerType === 'C' && item.SurveyAnswerChoice !== null) || (item.AnswerType === 'D' && item.SurveyAnswerInput !== null)) {
                survey.push({
                    "UserID": process.env.REACT_APP_SYSTEM_ID,
                    "TVSurveyID": item.TVSurveyID,
                    "SurveyAnswerChoice": item.SurveyAnswerChoice,
                    "SurveyAnswerInput": item.SurveyAnswerInput
                });
            }
        }

        const response = await tvs.post('/EPOD/PostTruckVisualSurveys', survey, {
            headers: {
                Authorization: `Bearer ${this.state.token}`
            }
        });
        if (response.status === 200 && response.data.status.code === '00') {
            this.setState({ isOpen: true });
        }
    }

    closeModal = () => {
        this.setState({ isOpen: false });
        const url = window.location.href;
        if (url.includes('Survey2')) {
            window.close();
        }
    }

    render() {
        if (this.state.isLoading && !this.state.expired) {
            return (
                <div className="h-100 flex-container-vertical flex-center">
                    <div className="spinner m-32">
                        <div className="bounce1 dark"></div>
                        <div className="bounce2 dark"></div>
                        <div className="bounce3 dark"></div>
                    </div>
                </div>
            );
        } else if (this.state.expired) {
            return (
                <div className="h-100 flex-container-vertical flex-center">
                    <h5>Hash has Expired.</h5>
                </div>
            );
        }
        let disabledButton = true;
        if (this.state.survey.length >= 4) {
            disabledButton = (this.state.survey[0].AnswerType === 'C' && this.state.survey[0].SurveyAnswerChoice !== null) &&
                (this.state.survey[1].AnswerType === 'C' && this.state.survey[1].SurveyAnswerChoice !== null) &&
                (this.state.survey[2].AnswerType === 'C' && this.state.survey[2].SurveyAnswerChoice !== null) &&
                (this.state.survey[3].AnswerType === 'C' && this.state.survey[3].SurveyAnswerChoice !== null);
        }
        return (
            <div className="h-100 flex-container-vertical">
                <div className="bg-blue">
                    <div className="flex-container-horizontal pt-24 pl-48 pb-24" style={{ alignItems: 'center' }}>
                        <i className="material-icons md-light md-24">
                            forum
                        </i>
                        <h3 className="cl-white mv-24">Customer Survey</h3>
                    </div>
                </div>
                <h3 style={{ textAlign: 'center' }} className="p-24">
                    แบบประเมินความพึงพอใจในการจัดส่ง
                </h3>
                <div className="p-16 flex-1" style={{ overflowY: 'scroll' }}>
                    {this.renderSurvey()}
                    <div className="flex-container-horizontal flex-center">
                        <button disabled={!disabledButton} className={`center btn-confirm ${!disabledButton && 'opacity'}`} onClick={() => this.onSubmit()}>Submit</button>
                    </div>
                </div>
                <Modal
                    isOpen={this.state.isOpen}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                >
                    <h3 style={{ textAlign: 'center' }}>ทำรายการสำเร็จ</h3>
                    <h5 className="m-16">ระบบได้บันทึกแบบสอบถามของท่านแล้ว</h5>
                    <button onClick={() => this.closeModal()} id="confirm" className="btn">ตกลง</button>
                </Modal>
            </div>
        );
    }
}
export default Survey