import { FETCH_NOTIFICATIONS, UPDATE_NOTIFICATION } from '../actions/type';

export default (state = [], action) => {
    switch (action.type) {
        case FETCH_NOTIFICATIONS:
            return action.payload;
        case UPDATE_NOTIFICATION:
            const index = state.indexOf(action.payload);
            let notifications = [ ...state ];
            notifications[index].IsReview = 'True';
            return notifications;
        default:
            return state;
    }
}