import { LOGIN, LOGOUT, SET_LOADING } from '../actions/type';

const INITIAL_STATE = {
    token: '',
    expiredIn: 0,
    name: '',
    isLogin: false,
    userId: '',
    userType: '',
    isLoading: false,
    apiHash: ''
}
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case LOGIN:
            return { ...action.payload }
        case LOGOUT:
            return { ...INITIAL_STATE }
        case SET_LOADING:
            return { ...state, isLoading: action.payload } 
        default:
            return state;
    }
}